//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  // name: 'ComponentName',
  data: function data() {
    return {};
  },
  methods: {
    switchToTourny: function switchToTourny() {
      this.$store.dispatch('player/switchToTourny');
      this.$store.dispatch('modals/setSwitchModal', {
        isOpen: false
      });
    },
    switchToSweeps: function switchToSweeps() {
      this.$store.dispatch('player/switchToSweeps');
      this.$store.dispatch('modals/setSwitchModal', {
        isOpen: false
      });
    }
  }
};